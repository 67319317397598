import React from 'react';

const ContactUs = () => {
  return (
    <div className="min-h-screen  flex items-center justify-center px-4 py-8">
    <div className="faqs p-6 max-w-4xl mx-auto bg-white">
      <h1 className="text-3xl font-bold mb-4 color-blue-">Get in touch</h1>
      <p className="text-sm text-gray-500 mb-8">Last Updated: 18-11-2024</p>

      <div className="space-y-6">
        {/* Question 1 */}
        <div>
          <h2 className="text-xl font-semibold mb-2">Have a question about GameZoone...?</h2>
          
        </div>

        {/* Question 2 */}
        <div>
          <h2 className="text-xl font-semibold mb-2">We'd love to hear from you!</h2>
        </div>

        {/* Question 3 */}
        <div>
          <h2 className="text-xl font-semibold mb-2">Send us a message and we'll get back to you as soon as possible: gamezoone.online@gmail.com</h2>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ContactUs;
