import React, { useRef } from "react";

const GameModal = ({ game, onClose }) => {
  const iframeRef = useRef(null);

  if (!game) return null;

  const enterFullScreen = () => {
    if (iframeRef.current) {
      if (iframeRef.current.requestFullscreen) {
        iframeRef.current.requestFullscreen();
      } else if (iframeRef.current.webkitRequestFullscreen) {
        iframeRef.current.webkitRequestFullscreen(); // Safari
      } else if (iframeRef.current.mozRequestFullScreen) {
        iframeRef.current.mozRequestFullScreen(); // Firefox
      } else if (iframeRef.current.msRequestFullscreen) {
        iframeRef.current.msRequestFullscreen(); // IE/Edge
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
      <div className="relative bg-white w-full max-w-4xl h-[80vh] rounded-lg overflow-hidden shadow-lg">
        <button
          onClick={onClose}
          className="absolute top-6 right-16 bg-red-500 text-white rounded-full px-3 py-1"
        >
          Close
        </button>
        <button
          onClick={enterFullScreen}
          className="absolute top-16 right-9 bg-blue-500 text-white rounded-full px-3 py-1"
        >
          Full Screen
        </button>
        <div className="h-full">
          <iframe
            ref={iframeRef}
            src={game.path}
            title={game.name}
            className="w-full h-full"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default GameModal;
