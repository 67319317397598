import React, { useEffect, useState } from "react";
import GameGrid from "./../components/GameGrid";
import GameModal from "./../components/GameModal";

const Home = ({ searchQuery, selectedCategory }) => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  

  const openModal = (game) => {
    setSelectedGame(game);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedGame(null);
    setIsModalOpen(false);
  };

  return (
    <div className="home-container">
      <GameGrid 
        searchQuery={searchQuery} 
        selectedCategory={selectedCategory} 
        onGameSelect={openModal} 
      />
      {isModalOpen && <GameModal game={selectedGame} onClose={closeModal} />}
    </div>
  );
};

export default Home;
