import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen  flex items-center justify-center px-4 py-8">
      {/* Container for the Privacy Policy */}
      <div className="bg-white max-w-4xl w-full mx-auto p-6 md:p-10 rounded-lg shadow-lg">
        {/* Title */}
        <h1 className="text-2xl md:text-4xl font-bold mb-6 text-center leading-tight">
          Privacy Policy for GameZoone.online
        </h1>
        <p className="text-sm md:text-base text-gray-500 mb-10 text-center">
          Effective Date: 12-11-2024
        </p>

        {/* Content */}
        <p className="mb-6 text-justify text-sm md:text-base leading-relaxed">
          Welcome to GameZoone.online! We care about and respect your privacy.
          This Privacy Policy describes what and how we do, ensuring there is no
          need to worry when visiting our site since we do not store any personal
          information, cookies, or tracking data about your visit.
        </p>

        <h2 className="text-lg md:text-xl font-semibold mb-4">No Data Collection</h2>
        <p className="mb-6 text-justify text-sm md:text-base leading-relaxed">
          GameZoone.online does not ask for, collect, store, or process any of your
          personal information. As an easy and fun anonymous game platform, we
          require zero user data.
        </p>

        <h2 className="text-lg md:text-xl font-semibold mb-4">Cookies and Other Tracking Technologies</h2>
        <p className="mb-4 text-justify text-sm md:text-base leading-relaxed">
          We do not use cookies or other tracking technologies on our site. This
          means:
        </p>
        <ul className="list-disc list-inside mb-6 space-y-2 text-sm md:text-base">
          <li>We only save cookies on your machine.</li>
          <li>We do not utilize any type of tracking or analytics tools to analyze your actions on the site.</li>
          <li>Your activity on GameZoone.online stays absolutely anonymous.</li>
        </ul>

        <h2 className="text-lg md:text-xl font-semibold mb-4">Third-Party Advertising</h2>
        <p className="mb-6 text-justify text-sm md:text-base leading-relaxed">
          GameZoone.online is supported by advertisements through Google AdSense.
          Although we do not save any data, please be notified that Google might
          use cookies or other technologies to present relevant ads. You can review
          Google’s Privacy Policy{' '}
          <a href="https://policies.google.com/privacy?hl=en-US" className="text-blue-500 underline">
            here
          </a>{' '}
          and adjust your ad preferences in your Google account settings.
        </p>

        <h2 className="text-lg md:text-xl font-semibold mb-4">Children’s Privacy</h2>
        <p className="mb-6 text-justify text-sm md:text-base leading-relaxed">
          GameZoone.online is not intended for children under 4 years old. No
          information is collected, even if the user is a child; we aim to create a
          safe and anonymous environment for all users.
        </p>

        <h2 className="text-lg md:text-xl font-semibold mb-4">Modifications to This Privacy Policy</h2>
        <p className="mb-6 text-justify text-sm md:text-base leading-relaxed">
          This Privacy Policy may be updated periodically to reflect legal updates
          or changes in our practices. The "Effective Date" at the beginning of
          this policy indicates the last update.
        </p>

        <h2 className="text-lg md:text-xl font-semibold mb-4">Contact Us</h2>
        <p className="mb-4 text-justify text-sm md:text-base leading-relaxed">
          If you have any questions about this Privacy Policy or our practices,
          please contact us at:
        </p>
        <p className="mb-6 font-semibold text-sm md:text-base text-center">
          Email:{' '}
          <a href="mailto:gamezoone.online@gmail.com" className="text-blue-500 underline">
            gamezoone.online@gmail.com
          </a>
        </p>

        <p className="mt-8 text-center text-sm md:text-base">
          Thank you for visiting GameZoone.online. Enjoy great games with full
          respect for your privacy.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
