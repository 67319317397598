import React, { useState, useEffect } from "react";
import GameModal from "./GameModal";


// Import your game images
import gameImage1 from "./../img/3D-city.png";
import gameImage2 from "./../img/2048-lite.png";
import gameImage3 from "./../img/chess.png";
import gameImage4 from "./../img/8-ball-pool.png";
import gameImage5 from "./../img/guiter.jpg";
import gameImage6 from "./../img/Clumsy-bird.png";
import gameImage7 from "./../img/Colorful-life.png";
import gameImage8 from "./../img/Dead-valley.png";
import gameImage9 from "./../img/Eight-queen.png";
import gameImage10 from "./../img/Fire-n-ice.png";

import gameImage11 from "./../img/Paint-run.png";
import gameImage12 from "./../img/1&1.png";
import gameImage13 from "./../img/og_image.png";
import gameImage14 from "./../img/achievementunlocked.png";
import gameImage15 from "./../img/adrenalinechallenge.jpg";
import gameImage16 from "./../img/red.png";
import gameImage17 from "./../img/Backrooms.jpg";
import gameImage18 from "./../img/basketball-stars.png";
import gameImage19 from "./../img/battleforgondor.jpg";
import gameImage20 from "./../img/Big tower.png";

import gameImage21 from "./../img/bigredbutton.png";
import gameImage22 from "./../img/blackknight.png";
import gameImage23 from "./../img/block.png";
import gameImage24 from "./../img/bloons.jpg";
import gameImage25 from "./../img/bloonstd.jpg";
import gameImage26 from "./../img/bloonstd2.png";
import gameImage27 from "./../img/boxhead2play.jpg";
import gameImage28 from "./../img/breakingthebank.png";
import gameImage29 from "./../img/Cannon-basketball-4.png";
import gameImage30 from "./../img/canyondefense.png";

import gameImage32 from "./../img/Cell-machine.png";
import gameImage33 from "./../img/championarcher.png";
import gameImage34 from "./../img/case1.png";
import gameImage35 from "./../img/chrome-dino.ico";
import gameImage36 from "./../img/circlo.png";
import gameImage37 from "./../img/connect3.png";
import gameImage39 from "./../img/creativekillchamber.jpg";
import gameImage40 from "./../img/ctr.png";

import gameImage41 from "./../img/ctr-holiday.webp";
import gameImage42 from "./../img/ctr-tr.png";
import gameImage43 from "./../img/cubefield.jpg";
import gameImage44 from "./../img/cupcake2048.png";
import gameImage45 from "./../img/deal-or-no-deal.jpg";
import gameImage46 from "./../img/death-run-3d.png";
import gameImage47 from "./../img/defend-the-tank.jpg";
import gameImage48 from "./../img/doge2048.jpeg";
import gameImage49 from "./../img/DogeMiner.ico";
import gameImage50 from "./../img/doodle-jump.png";

import gameImage51 from "./../img/doublewires.png";
import gameImage53 from "./../img/ducklife1.png";
import gameImage54 from "./../img/ducklife2.png";
import gameImage55 from "./../img/ducklife3.png";
import gameImage56 from "./../img/ducklife4.jpg";
import gameImage57 from "./../img/edgenotfound.png";
import gameImage60 from "./../img/endlesswar3.png";

import gameImage61 from "./../img/evil-glitch.png";
import gameImage62 from "./../img/exo.jpg";
import gameImage63 from "./../img/ezr2.jpg";
import gameImage65 from "./../img/flashtetris.png";
import gameImage66 from "./../img/game-inside.png";
import gameImage67 from "./../img/geodash.png";
import gameImage68 from "./../img/georgeandtheprinter.png";
import gameImage69 from "./../img/getaway-shootout.jpg";
import gameImage70 from "./../img/gimme-the-airpod.png";

import gameImage71 from "./../img/google-snake.png";
import gameImage72 from "./../img/grindcraft.png";
import gameImage73 from "./../img/hackertype.ico";
import gameImage74 from "./../img/helicopter.png";
import gameImage75 from "./../img/hexempire.jpg";
import gameImage76 from "./../img/HexGL.png";
import gameImage77 from "./../img/idle-breakout.png";
import gameImage78 from "./../img/idle-shark.png";
import gameImage79 from "./../img/impossiblequiz.png";
import gameImage80 from "./../img/indestructotank.png";

import gameImage81 from "./../img/interactivebuddy.jpg";
import gameImage82 from "./../img/just-one-boss.png";
import gameImage83 from "./../img/kittencannon.png";
import gameImage84 from "./../img/learntofly.png";
import gameImage85 from "./../img/madalin-stunt-cars-2.jpg";
import gameImage86 from "./../img/madalin-stunt-cars-3.jpg";
import gameImage87 from "./../img/mario.gif";
import gameImage88 from "./../img/matrixrampage.jpg";
import gameImage90 from "./../img/minecraft-classic.png";
 
import gameImage91 from "./../img/minesweeper.png";
import gameImage92 from "./../img/miniputt.png";
import gameImage93 from "./../img/missiles.png";
import gameImage94 from "./../img/motox3m.jpg";
import gameImage95 from "./../img/motox3m-pool.jpg";
import gameImage96 from "./../img/motox3m-spooky.jpeg";
import gameImage97 from "./../img/motox3m-winter.jpeg";
import gameImage98 from "./../img/n-gon.png";
import gameImage99 from "./../img/ninja.png";
import gameImage100 from "./../img/ns-shaft.png";

import gameImage101 from "./../img/pandemic2.png";
import gameImage102 from "./../img/papaspizzaria.jpg";
import gameImage103 from "./../img/paperio2.png";
import gameImage104 from "./../img/papery-planes.jpg";
import gameImage105 from "./../img/pingpong.png";
import gameImage106 from "./../img/polybranch.png";
import gameImage107 from "./../img/portalflash.jpg";
import gameImage108 from "./../img/push-the-square.png";
import gameImage109 from "./../img/push-your-luck.png";
import gameImage110 from "./../img/rolly-vortex.png";

import gameImage111 from "./../img/rooftop-snipers.png";
import gameImage113 from "./../img/scrapmetal.png";
import gameImage114 from "./../img/slope.jpeg";
import gameImage115 from "./../img/sm64.png";
import gameImage117 from "./../img/smokingbarrels.jpg";
import gameImage118 from "./../img/snowbattle.png";
import gameImage119 from "./../img/soldier-legend.jpg";
import gameImage120 from "./../img/solitaire.png";

import gameImage121 from "./../img/sort-the-court.png";
import gameImage122 from "./../img/spankthemonkey.jpg";
import gameImage123 from "./../img/stack.png";
import gameImage124 from "./../img/stack-bump-3d.jpg";
import gameImage125 from "./../img/stealingthediamond.jpg";
import gameImage126 from "./../img/stickwar.jpg";
import gameImage127 from "./../img/stormthehouse2.jpg";
import gameImage128 from "./../img/superhot.jpg";
import gameImage129 from "./../img/swerve.jpg";
import gameImage130 from "./../img/tacticalassasin2.png";

import gameImage131 from "./../img/tank-trouble-2.jpeg";
import gameImage132 from "./../img/tanuki-sunset.png";
import gameImage133 from "./../img/temple-run-2.png";
import gameImage134 from "./../img/tetris.png";
import gameImage135 from "./../img/thebattle.png";
import gameImage136 from "./../img/theheist.jpg";
import gameImage137 from "./../img/the-lost-ball.png";
import gameImage138 from "./../img/there-is-no-game.png";
import gameImage139 from "./../img/thingthing4.jpg";
import gameImage140 from "./../img/thingthingarena3.jpg";

import gameImage141 from "./../img/thisistheonlylevel.png";
import gameImage142 from "./../img/tosstheturtle.png";
import gameImage143 from "./../img/townscaper.jpg";
import gameImage144 from "./../img/tube-jumpers.jpg";
import gameImage145 from "./../img/tunnel-rush.jpg";
import gameImage146 from "./../img/worlds-hardest-game.jpg";
import gameImage147 from "./../img/worlds-hardest-game-2.png";


// ... (add all other imports for the images)

const games = [
{ id: 1, name: "3d.city", image: gameImage1, size: "2x2", category: "Adventure", path: "../Games/3d.city/index.html" },
{ id: 2, name: "2048-lite", image: gameImage2, size: "1x1", category: "Plus-Minus", path: "../Games/2048-lite/index.html" },
{ id: 3, name: "chess", image: gameImage3, size: "1x1", category: "Sports", path: "../Games/chess/index.html" },
{ id: 4, name: "classic-pool", image: gameImage4, size: "1x1", category: "Sports", path: "../Games/classic-pool/index.html" },
{ id: 5, name: "chordomatic", image: gameImage5, size: "1x1", category: "Zoone-Games", path: "../Games/chordomatic/index.html" },
{ id: 6, name: "clumsy-bird", image: gameImage6, size: "2x2", category: "Zoone-Games", path: "../Games/clumsy-bird/index.html" },
{ id: 7, name: "colorful-lifedx", image: gameImage7, size: "1x1", category: "Zoone-Games", path: "../Games/colorful-life/index.html" },
{ id: 8, name: "dead-valle", image: gameImage8, size: "1x1", category: "Car", path: "../Games/dead-valley/index.html" },
{ id: 9, name: "EightQueens", image: gameImage9, size: "1x1", category: "Sports", path: "../Games/EightQueens/index.html" },
{ id: 10, name: "fire-n-ice", image: gameImage10, size: "1x1", category: "Adventure", path: "../Games/fire-n-ice/index.html" },


{ id: 16, name: "among-us", image: gameImage16, size: "2x2", category: "Adventure", path: "../Games/Game/among-us/index.html" },
{ id: 12, name: "1v1space", image: gameImage12, size: "1x1", category: "Multiplayer", path: "../Games/Game/1v1space/index.html" },
{ id: 13, name: "2048", image: gameImage13, size: "1x1", category: "Zoone-Games", path: "../Games/Game/1/index.html" },
{ id: 14, name: "achievementunlocked", image: gameImage14, size: "1x1", category: "Zoone-Games", path: "../Games/Game/achievementunlocked/index.html" },
{ id: 15, name: "adrenalinechallenge", image: gameImage15, size: "1x1", category: "Car", path: "../Games/Game/adrenalinechallenge/index.html" },
{ id: 17, name: "backrooms", image: gameImage17, size: "1x1", category: "Zoone-Games", path: "../Games/Game/backrooms/index.html" },
{ id: 18, name: "basketball-stars", image: gameImage18, size: "1x1", category: "Sports", path: "../Games/Game/basketball-stars/index.html" },
{ id: 19, name: "battleforgondor", image: gameImage19, size: "1x1", category: "Adventure", path: "../Games/Game/battleforgondor/index.html" },
{ id: 20, name: "btts", image: gameImage20, size: "1x1", category: "Zoone-Games", path: "../Games/Game/btts/index.html" },


{ id: 21, name: "bigredbutton", image: gameImage21, size: "1x1", category: "Zoone-Games", path: "../Games/Game/bigredbutton/index.html" },
{ id: 22, name: "blackknight", image: gameImage22, size: "1x1", category: "Adventure", path: "../Games/Game/blackknight/index.html" },
{ id: 23, name: "bloxors", image: gameImage23, size: "2x2", category: "Zoone-Games", path: "../Games/Game/bloxors/index.html" },
{ id: 24, name: "bloons", image: gameImage24, size: "1x1", category: "Zoone-Games", path: "../Games/Game/bloons/index.html" },
{ id: 11, name: "paint-run", image: gameImage11, size: "2x2", category: "Zoone-Games", path: "../Games/paint-run/index.html" },
{ id: 25, name: "bloonstd", image: gameImage25, size: "1x1", category: "Adventure", path: "../Games/Game/bloonstd/index.html" },
{ id: 26, name: "bloonstd2", image: gameImage26, size: "1x1", category: "Adventure", path: "../Games/Game/bloonstd2/index.html" },
{ id: 27, name: "boxhead2play", image: gameImage27, size: "1x1", category: "Zoone-Games", path: "../Games/Game/boxhead2play/index.html" },
{ id: 28, name: "breakingthebank", image: gameImage28, size: "1x1", category: "Car", path: "../Games/Game/breakingthebank/index.html" },
{ id: 29, name: "cannon-basketball-4", image: gameImage29, size: "1x1", category: "Action", path: "../Games/Game/cannon-basketball-4/index.html" },
{ id: 30, name: "canyondefense", image: gameImage30, size: "1x1", category: "Adventure", path: "../Games/Game/canyondefense/index.html" },

{ id: 32, name: "cell-machine", image: gameImage32, size: "1x1", category: "Zoone-Games", path: "../Games/Game/cell-machine/index.html" },
{ id: 33, name: "championarcher", image: gameImage33, size: "1x1", category: "Action", path: "../Games/Game/championarcher/index.html" },
{ id: 34, name: "csgo-clicker", image: gameImage34, size: "1x1", category: "Action", path: "../Games/Game/csgo-clicker/index.html" },
{ id: 35, name: "chrome-dino", image: gameImage35, size: "1x1", category: "Zoone-Games", path: "../Games/Game/chrome-dino/index.html" },
{ id: 36, name: "circlo", image: gameImage36, size: "1x1", category: "Zoone-Games", path: "../Games/Game/circlo/index.html" },
{ id: 37, name: "connect3", image: gameImage37, size: "1x1", category: "Plus-Minus", path: "../Games/Game/connect3/index.html" },
{ id: 39, name: "creativekillchamber", image: gameImage39, size: "1x1", category: "Action", path: "../Games/Game/creativekillchamber/index.html" },
{ id: 40, name: "Cut the Rope", image: gameImage40, size: "2x2", category: "Zoone-Games", path: "../Games/Game/ctr/index.html" },

{ id: 41, name: "Cut the Rope -holiday", image: gameImage41, size: "1x1", category: "Zoone-Games", path: "../Games/Game/ctr-holiday/index.html" },
{ id: 42, name: "Cut the Rope-tr", image: gameImage42, size: "1x1", category: "Zoone-Games", path: "../Games/Game/ctr-tr/index.html" },
{ id: 43, name: "cubefield", image: gameImage43, size: "1x1", category: "Zoone-Games", path: "../Games/Game/cubefield/index.html" },
{ id: 44, name: "cupcake2048", image: gameImage44, size: "1x1", category: "Plus-Minus", path: "../Games/Game/cupcake2048/index.html" },
{ id: 45, name: "deal-or-no-deal", image: gameImage45, size: "2x2", category: "Action", path: "../Games/Game/deal-or-no-deal/index.html" },
{ id: 46, name: "death-run-3d", image: gameImage46, size: "1x1", category: "Zoone-Games", path: "../Games/Game/death-run-3d/index.html" },
{ id: 47, name: "defend-the-tank", image: gameImage47, size: "1x1", category: "Action", path: "../Games/Game/defend-the-tank/index.html" },
{ id: 48, name: "doge2048", image: gameImage48, size: "1x1", category: "Plus-Minus", path: "../Games/Game/doge2048/index.html" },
{ id: 49, name: "DogeMiner", image: gameImage49, size: "1x1", category: "Plus-Minus", path: "../Games/Game/DogeMiner/index.html" },
{ id: 50, name: "doodle-jump", image: gameImage50, size: "1x1", category: "Zoone-Games", path: "../Games/Game/doodle-jump/index.html" },

{ id: 51, name: "doublewires", image: gameImage51, size: "1x1", category: "Adventure", path: "../Games/Game/doublewires/index.html" },
{ id: 53, name: "ducklife1", image: gameImage53, size: "1x1", category: "Zoone-Games", path: "../Games/Game/ducklife1/index.html" },
{ id: 54, name: "ducklife2", image: gameImage54, size: "1x1", category: "Zoone-Games", path: "../Games/Game/ducklife2/index.html" },
{ id: 55, name: "ducklife3", image: gameImage55, size: "1x1", category: "Zoone-Games", path: "../Games/Game/ducklife3/index.html" },
{ id: 56, name: "ducklife4", image: gameImage56, size: "1x1", category: "Zoone-Games", path: "../Games/Game/ducklife4/index.html" },
{ id: 57, name: "edgenotfound", image: gameImage57, size: "1x1", category: "Adventure", path: "../Games/Game/edgenotfound/index.html" },
{ id: 60, name: "endlesswar3", image: gameImage60, size: "1x1", category: "Action", path: "../Games/Game/endlesswar3/index.html" },

{ id: 61, name: "evil-glitch", image: gameImage61, size: "2x2", category: "Action", path: "../Games/Game/evil-glitch/index.html" },
{ id: 62, name: "exo", image: gameImage62, size: "1.5x1.5", category: "Adventure", path: "../Games/Game/exo/index.html" },
{ id: 63, name: "ezr2", image: gameImage63, size: "1.5x1.5", category: "Action", path: "../Games/Game/ezr2/index.html" },

{ id: 65, name: "flashtetris", image: gameImage65, size: "1x1", category: "Plus-Minus", path: "../Games/Game/flashtetris/index.html" },
{ id: 66, name: "game-inside", image: gameImage66, size: "1x1", category: "Zoone-Games", path: "../Games/Game/game-inside/index.html" },
{ id: 67, name: "geodash", image: gameImage67, size: "2x2", category: "Zoone-Games", path: "../Games/Game/geodash/index.html" },
{ id: 68, name: "georgeandtheprinter", image: gameImage68, size: "1x1", category: "Zoone-Games", path: "../Games/Game/georgeandtheprinter/index.html" },
{ id: 69, name: "getaway-shootout", image: gameImage69, size: "1x1", category: "Action", path: "../Games/Game/getaway-shootout/index.html" },
{ id: 70, name: "gimme-the-airpod", image: gameImage70, size: "2x2", category: "Action", path: "../Games/Game/gimme-the-airpod/index.html" },

{ id: 71, name: "google-snake", image: gameImage71, size: "1x1", category: "Adventure", path: "../Games/Game/google-snake/index.html" },
{ id: 72, name: "grindcraft", image: gameImage72, size: "1x1", category: "Zoone-Games", path: "../Games/Game/grindcraft/index.html" },
{ id: 73, name: "hackertype", image: gameImage73, size: "1x1", category: "", path: "../Games/Game/hackertype/index.html" },
{ id: 74, name: "helicopter", image: gameImage74, size: "1x1", category: "Adventure", path: "../Games/Game/helicopter/index.html" },
{ id: 75, name: "hexempire", image: gameImage75, size: "1x1", category: "Action", path: "../Games/Game/hexempire/index.html" },
{ id: 76, name: "HexGL", image: gameImage76, size: "1x1", category: "Action", path: "../Games/Game/HexGL/index.html" },
{ id: 77, name: "idle-breakout", image: gameImage77, size: "1x1", category: "Zoone-Games", path: "../Games/Game/idle-breakout/index.html" },
{ id: 78, name: "idle-shark", image: gameImage78, size: "1x1", category: "Adventure", path: "../Games/Game/idle-shark/index.html" },
{ id: 79, name: "impossiblequiz", image: gameImage79, size: "1x1", category: "", path: "../Games/Game/impossiblequiz/index.html" },
{ id: 80, name: "indestructotank", image: gameImage80, size: "1x1", category: "Action", path: "../Games/Game/indestructotank/index.html" },

{ id: 81, name: "interactivebuddy", image: gameImage81, size: "1x1", category: "", path: "../Games/Game/interactivebuddy/index.html" },
{ id: 82, name: "just-one-boss", image: gameImage82, size: "1x1", category: "Zoone-Games", path: "../Games/Game/just-one-boss/index.html" },
{ id: 83, name: "kittencannon", image: gameImage83, size: "1x1", category: "Action", path: "../Games/Game/kittencannon/index.html" },
{ id: 84, name: "learntofly", image: gameImage84, size: "1x1", category: "Action", path: "../Games/Game/learntofly/index.html" },
{ id: 85, name: "madalin-stunt-cars-2", image: gameImage85, size: "1x1", category: "Car", path: "../Games/Game/madalin-stunt-cars-2/index.html" },
{ id: 86, name: "madalin-stunt-cars-3", image: gameImage86, size: "1x1", category: "Car", path: "../Games/Game/madalin-stunt-cars-3/index.html" },
{ id: 87, name: "mario", image: gameImage87, size: "2x2", category: "", path: "../Games/Game/mario/index.html" },
{ id: 88, name: "matrixrampage", image: gameImage88, size: "1x1", category: "Action", path: "../Games/Game/matrixrampage/index.html" },
{ id: 90, name: "minecraft-classic", image: gameImage90, size: "2x2", category: "Adventure", path: "../Games/Game/minecraft-classic/index.html" },

{ id: 91, name: "minesweeper", image: gameImage91, size: "1x1", category: "Action", path: "../Games/Game/minesweeper/index.html" },
{ id: 92, name: "miniputt", image: gameImage92, size: "1x1", category: "Zoone-Games", path: "../Games/Game/miniputt/index.html" },
{ id: 93, name: "missiles", image: gameImage93, size: "2x2", category: "", path: "../Games/Game/missiles/index.html" },
{ id: 94, name: "motox3m", image: gameImage94, size: "1x1", category: "Car", path: "../Games/Game/motox3m/index.html" },
{ id: 95, name: "motox3m-pool", image: gameImage95, size: "1x1", category: "Car", path: "../Games/Game/motox3m-pool/index.html" },
{ id: 96, name: "motox3m-spooky", image: gameImage96, size: "2x2", category: "Car", path: "../Games/Game/motox3m-spooky/index.html" },
{ id: 97, name: "motox3m-winter", image: gameImage97, size: "1x1", category: "Car", path: "../Games/Game/motox3m-winter/index.html" },
{ id: 98, name: "n-gon", image: gameImage98, size: "1x1", category: "Zoone-Games", path: "../Games/Game/n-gon/index.html" },
{ id: 99, name: "ninja", image: gameImage99, size: "1x1", category: "Action", path: "../Games/Game/ninja/index.html" },
{ id: 100, name: "ns-shaft", image: gameImage100, size: "1x1", category: "Action", path: "../Games/Game/ns-shaft/index.html" },

{ id: 101, name: "pandemic2", image: gameImage101, size: "1x1", category: "Zoone-Games", path: "../Games/Game/pandemic2/index.html" },
{ id: 102, name: "papaspizzaria", image: gameImage102, size: "1x1", category: "", path: "../Games/Game/papaspizzaria/index.html" },
{ id: 103, name: "paperio2", image: gameImage103, size: "1x1", category: "Zoone-Games", path: "../Games/Game/paperio2/index.html" },
{ id: 104, name: "papery-planes", image: gameImage104, size: "2x2", category: "Zoone-Games", path: "../Games/Game/papery-planes/index.html" },
{ id: 105, name: "pingpong", image: gameImage105, size: "1x1", category: "Sports", path: "../Games/Game/pingpong/index.html" },
{ id: 106, name: "polybranch", image: gameImage106, size: "1x1", category: "Zoone-Games", path: "../Games/Game/polybranch/index.html" },
{ id: 107, name: "portalflash", image: gameImage107, size: "1x1", category: "Action", path: "../Games/Game/portalflash/index.html" },
{ id: 108, name: "push-the-square", image: gameImage108, size: "1x1", category: "Zoone-Games", path: "../Games/Game/push-the-square/index.html" },
{ id: 109, name: "push-your-luck", image: gameImage109, size: "1x1", category: "", path: "../Games/Game/push-your-luck/index.html" },
{ id: 110, name: "rolly-vortex", image: gameImage110, size: "1x1", category: "", path: "../Games/Game/rolly-vortex/index.html" },

{ id: 111, name: "rooftop-snipers", image: gameImage111, size: "1x1", category: "Action", path: "../Games/Game/rooftop-snipers/index.html" },

{ id: 113, name: "scrapmetal", image: gameImage113, size: "1x1", category: "Car", path: "../Games/Game/scrapmetal/index.html" },
{ id: 114, name: "slope", image: gameImage114, size: "2x2", category: "Zoone-Games", path: "../Games/Game/slope/index.html" },
{ id: 115, name: "sm64", image: gameImage115, size: "1x1", category: "", path: "../Games/Game/sm64/index.html" },

{ id: 117, name: "smokingbarrels", image: gameImage117, size: "1x1", category: "Action", path: "../Games/Game/smokingbarrels/index.html" },
{ id: 118, name: "snowbattle", image: gameImage118, size: "1x1", category: "Action", path: "../Games/Game/snowbattle/index.html" },
{ id: 119, name: "soldier-legend", image: gameImage119, size: "1x1", category: "Action", path: "../Games/Game/soldier-legend/index.html" },
{ id: 120, name: "solitaire", image: gameImage120, size: "1x1", category: "", path: "../Games/Game/solitaire/index.html" },

// { id: 121, name: "sort-the-court", image: gameImage121, size: "1x1", category: "Zoone-Games", path: "../Games/Game/sort-the-court/index.html" },
{ id: 122, name: "spankthemonkey", image: gameImage122, size: "1x1", category: "", path: "../Games/Game/spankthemonkey/index.html" },
{ id: 123, name: "stack", image: gameImage123, size: "2x2", category: "Plus-Minus", path: "../Games/Game/stack/index.html" },
{ id: 124, name: "stack-bump-3d", image: gameImage124, size: "1x1", category: "Plus-Minus", path: "../Games/Game/stack-bump-3d/index.html" },
{ id: 125, name: "stealingthediamond", image: gameImage125, size: "1x1", category: "", path: "../Games/Game/stealingthediamond/index.html" },
{ id: 126, name: "stickwar", image: gameImage126, size: "1x1", category: "Action", path: "../Games/Game/stickwar/index.html" },
{ id: 127, name: "stormthehouse2", image: gameImage127, size: "1x1", category: "", path: "../Games/Game/stormthehouse2/index.html" },
{ id: 128, name: "superhot", image: gameImage128, size: "1x1", category: "", path: "../Games/Game/superhot/index.html" },
{ id: 129, name: "swerve", image: gameImage129, size: "1x1", category: "Car", path: "../Games/Game/swerve/index.html" },
{ id: 130, name: "tacticalassasin2", image: gameImage130, size: "1x1", category: "Action", path: "../Games/Game/tacticalassasin2/index.html" },

{ id: 131, name: "tank-trouble-2", image: gameImage131, size: "1x1", category: "Action", path: "../Games/Game/tank-trouble-2/index.html" },
{ id: 132, name: "tanuki-sunset", image: gameImage132, size: "1x1", category: "Car", path: "../Games/Game/tanuki-sunset/index.html" },
{ id: 133, name: "temple-run-2", image: gameImage133, size: "2x2", category: "Adventure", path: "../Games/Game/temple-run-2/index.html" },
{ id: 134, name: "tetris", image: gameImage134, size: "1x1", category: "Zoone-Games", path: "../Games/Game/tetris/index.html" },
{ id: 135, name: "thebattle", image: gameImage135, size: "1x1", category: "Action", path: "../Games/Game/thebattle/index.html" },
{ id: 136, name: "theheist", image: gameImage136, size: "2x2", category: "Car", path: "../Games/Game/theheist/index.html" },
{ id: 137, name: "the-lost-ball", image: gameImage137, size: "1x1", category: "", path: "../Games/Game/the-lost-ball/index.html" },
{ id: 138, name: "there-is-no-game", image: gameImage138, size: "1x1", category: "", path: "../Games/Game/there-is-no-game/index.html" },
{ id: 139, name: "thingthing4", image: gameImage139, size: "1x1", category: "Action", path: "../Games/Game/thingthing4/index.html" },
{ id: 140, name: "thingthingarena3", image: gameImage140, size: "1x1", category: "Action", path: "../Games/Game/thingthingarena3/index.html" },

{ id: 141, name: "thisistheonlylevel", image: gameImage141, size: "1x1", category: "Zoone-Games", path: "../Games/Game/thisistheonlylevel/index.html" },
{ id: 142, name: "tosstheturtle", image: gameImage142, size: "1x1", category: "Action", path: "../Games/Game/tosstheturtle/index.html" },
{ id: 143, name: "townscaper", image: gameImage143, size: "1x1", category: "", path: "../Games/Game/townscaper/index.html" },
{ id: 144, name: "tube-jumpers", image: gameImage144, size: "2x2", category: "Zoone-Games", path: "../Games/Game/tube-jumpers/index.html" },
{ id: 145, name: "tunnel-rush", image: gameImage145, size: "1x1", category: "", path: "../Games/Game/tunnel-rush/index.html" },
{ id: 146, name: "worlds-hardest-game", image: gameImage146, size: "1x1", category: "", path: "../Games/Game/worlds-hardest-game/index.html" },
{ id: 147, name: "worlds-hardest-game-2", image: gameImage147, size: "2x2", category: "", path: "../Games/Game/worlds-hardest-game-2/index.html" },


 // Define the rest with varying sizes as needed
];


const GameGrid = ({ selectedCategory, searchQuery }) => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;

  // Reset to the first page whenever searchQuery or selectedCategory changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery, selectedCategory]);

  const openModal = (game) => {
    setSelectedGame(game);
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setSelectedGame(null);
    setIsModalOpen(false);
  };

  const getSizeClasses = (size) => {
    switch (size) {
      case "2x2":
        return "w-full h-[300px] col-span-2 row-span-2";
      case "2x1":
        return "w-full h-[150px] col-span-2 row-span-1";
      case "1x2":
        return "w-full h-[300px] col-span-1 row-span-2";
      case "3x3":
        return "w-full h-[450px] col-span-3 row-span-3";
      case "1x1":
      default:
        return "w-full h-[150px] col-span-1 row-span-1";
    }
  };

  // Filter games based on category and search query
  const filteredGames = games.filter((game) => {
    const matchesCategory = !selectedCategory || game.category === selectedCategory;
    const matchesSearch = !searchQuery || game.name.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  // Pagination logic
  const indexOfLastGame = currentPage * itemsPerPage;
  const indexOfFirstGame = indexOfLastGame - itemsPerPage;
  const currentGames = filteredGames.slice(indexOfFirstGame, indexOfLastGame);
  const totalPages = Math.ceil(filteredGames.length / itemsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
      window.scrollTo(0, 0); // Scroll to top on page change
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      window.scrollTo(0, 0); // Scroll to top on page change
    }
  };

  return (
    <div className="scrollable-background main-container relative w-full min-h-screen flex flex-col justify-between p-4">
      <div
        className="grid gap-4"
        style={{
          gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
          gridAutoFlow: "dense",
          gridAutoRows: "150px",
        }}
      >
        {currentGames.map((game) => (
          <div
            key={game.id}
            className={`relative cursor-pointer rounded-lg overflow-hidden ${getSizeClasses(game.size)}`}
            onClick={() => openModal(game)}
            style={{
              backgroundImage: `url(${game.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="absolute bottom-2 left-2 text-white font-semibold bg-black bg-opacity-50 px-2 py-1 rounded">
              {game.name}
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      {/* Pagination Controls */}
{totalPages > 1 && (
  <div className="flex justify-center mt-4">
    <button
      onClick={prevPage}
      disabled={currentPage === 1}
      className="px-4 py-2 mx-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50 font-semibold"
    >
      Previous
    </button>
    <span className="px-4 py-2 text-white text-2xl font-bold">
      Page {currentPage} of {totalPages}
    </span>
    <button
      onClick={nextPage}
      disabled={currentPage === totalPages}
      className="px-4 py-2 mx-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50 font-semibold"
    >
      Next
    </button>
  </div>
)}

      {/* Game Modal */}
      {isModalOpen && <GameModal game={selectedGame} onClose={closeModal} />}
    </div>
  );
};

export default GameGrid;
