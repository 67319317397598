import React from "react";

const TermsConditions = () => {
  return (
    <div className="terms-container px-6 py-10">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-6 text-center">
          Terms and Conditions
        </h1>
        <p className="mb-6 text-sm text-gray-500">
          Effective Date: 12-11-2024
        </p>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">1. Acceptance of Terms</h2>
          <p>
            By accessing and using GameZoone.online, you confirm that you have
            read, understood, and agree to abide by these Terms. If you do not
            agree to these Terms, please do not use the website.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">2. Use of the Website</h2>
          <ul className="list-disc pl-6">
            <li>Eligibility: You must be at least 4 years old to use this website.</li>
            <li>
              Permitted Use: GameZoone.online is provided for personal,
              non-commercial use only.
            </li>
            <li>
              Prohibited Activities: You agree not to disrupt or interfere with
              the website's operation or use the website for any illegal
              activities or in violation of applicable laws.
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">3. No Data Collection</h2>
          <p>
            GameZoone.online does not collect, store, or process any personal
            information or track user activity. However, third-party
            advertisers like Google AdSense may collect data through cookies or
            other tracking technologies. By using the site, you acknowledge
            that third-party data collection may occur as per their respective
            privacy policies.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">4. Open Source Content</h2>
          <p>
            All content, games, and materials available on GameZoone.online are
            open source unless otherwise stated. You are free to use, modify,
            and distribute the content in accordance with the principles of
            open-source licensing.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">5. Third-Party Advertising</h2>
          <p>
            GameZoone.online is supported by advertisements provided by Google
            AdSense. These ads may involve cookies or other tracking
            technologies managed by Google. You can manage your ad preferences
            through your Google account settings. GameZoone.online is not
            responsible for third-party data collection or advertising
            practices.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">
            6. Disclaimer of Warranties
          </h2>
          <p>
            - GameZoone.online is provided "as is" and "as available" without
            warranties of any kind, either express or implied. <br />
            - We do not guarantee uninterrupted access, error-free
            functionality, or compatibility with all devices or browsers.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">7. Limitation of Liability</h2>
          <p>
            To the fullest extent permitted by law: <br />
            - GameZoone.online shall not be liable for any indirect,
            incidental, or consequential damages arising from your use of the
            website. <br />
            - This includes, but is not limited to, loss of data, revenue, or
            business opportunities.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">8. Modifications to Terms</h2>
          <p>
            We may update these Terms periodically to reflect changes in our
            practices or for legal reasons. Any updates will be effective
            immediately upon posting on this page, and the "Effective Date"
            will indicate the latest revision.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">9. Governing Law</h2>
          <p>
            These Terms are governed by and construed in accordance with the
            laws of your jurisdiction. Any disputes arising from these Terms
            will be resolved in the applicable courts.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">10. Contact Us</h2>
          <p>
            If you have any questions or concerns about these Terms, please
            contact us at:
          </p>
          <p>Email: gamezoone.online@gmail.com</p>
        </div>
        <p className="text-center mt-6">
          Thank you for choosing GameZoone.online! Enjoy our games with total
          peace of mind.
        </p>
      </div>
    </div>
  );
};

export default TermsConditions;
