import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./../src/components/Header";
import Home from "./../src/pages/Home";
import PrivacyPolicy from "./../src/pages/PrivacyPolicy";
import TermsConditions from "./../src/pages/TermsConditions";
import ContactUs from "./../src/pages/ContactUs";
import FAQs from "./../src/pages/FAQs";
import Footer from "./../src/components/Footer";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim"


const App = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [init, setInit] = useState(false);

  // Handle category selection
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSearchQuery(""); // Clear search query when a category is selected
    // console.log(category);
  };

  // Handle search input change
  const handleSearchChange = (query) => {
    setSearchQuery(query);
    setSelectedCategory(""); // Clear selected category when a search query is entered
  };

  // Initialize particles engine once
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  // Particle options configuration
  const particleOptions = useMemo(
    () => ({
      background: {
        color: {
          value: "#1e1e2f", // Background color of particles
        },
      },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "grab",
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: "#ffffff",
        },
        links: {
          color: "#ffffff",
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: false,
          speed: 6,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 80,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
      detectRetina: true,
    }),
    []
  );

  return (
    <Router>
      <div className="relative flex flex-col min-h-screen">
        {/* Particles Background */}
        {init && (
          <Particles
            id="tsparticles"
            options={particleOptions}
            className="absolute inset-0 -z-10" // Ensure particles are behind all elements
          />
        )}

        {/* Header */}
        <Header
          onSearchChange={handleSearchChange}
          onCategorySelect={handleCategorySelect}
        />

        {/* Main Content */}
        <div className="flex-grow">
          <Routes>
            {/* Home Route */}
            <Route
              path="/"
              element={
                <Home
                  searchQuery={searchQuery}
                  selectedCategory={selectedCategory}
                />
              }
            />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            {/* Privacy Policy Route */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            {/* Contact Us Route */}
            <Route path="/contact-us" element={<ContactUs />} />

            {/* FAQs Route */}
            <Route path="/faqs" element={<FAQs />} />
          </Routes>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </Router>
  );
};

export default App;