import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="mt-auto bg-gradient-to-r from-[#0e0033] via-[#1f1249] to-[#250958] py-8 text-gray-300">
      <div className="container mx-auto px-4 md:px-8">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
          {/* Logo */}
          <div className="text-5xl font-bold text-white">
          <Link to="/">
            Game<span className="text-purple-300">Zoone</span>
          </Link>
        </div>

          {/* Footer Links */}
          <div className="flex text-1xl flex-wrap gap-4 mt-4">
            <Link
              to="/privacy-policy"
              className="hover:text-purple-500"
              aria-label="Privacy Policy"
            >
              Privacy Policy
            </Link>
            <Link
              to="/terms-conditions"
              className="hover:text-purple-500"
              aria-label="Terms & Conditions"
            >
              Terms & Conditions
            </Link>
            <Link
              to="/contact-us"
              className="hover:text-purple-500"
              aria-label="Contact Us"
            >
              Contact Us
            </Link>
            <Link
              to="/faqs"
              className="hover:text-purple-500"
              aria-label="FAQs"
            >
              FAQs
            </Link>
          </div>
        </div>

        {/* Copyright Notice */}
        <div className="mt-8 border-t border-gray-700 pt-4 text-center text-1xl text-gray-500">
          © 2024 GamesZoone, All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
