import React from 'react';

const FAQs = () => {
  return (
    <div className="min-h-screen  flex items-center justify-center px-4 py-8">
    <div className="bg-white max-w-4xl w-full  mx-auto p-6 md:p-10 rounded-lg shadow-lg py-8">
      <h1 className="text-3xl font-bold mb-4">FAQs (Frequently Asked Questions)</h1>
      <p className="text-sm text-gray-500 mb-8">Last Updated: 18-11-2024</p>

      <div className="space-y-6">
        {/* Question 1 */}
        <div>
          <h2 className="text-xl font-semibold mb-2">1. Is GameZoone.online free to play?</h2>
          <p className="mb-4">
            Yes, GameZoone.online is 100% free to play! You can play all games on our site free of charge, without having to create an account or pay for anything.
          </p>
        </div>

        {/* Question 2 */}
        <div>
          <h2 className="text-xl font-semibold mb-2">2. Are the games on GameZoone.online open source?</h2>
          <p className="mb-4">
            Yes, all games on GameZoone.online are 100% open source. As we strive to embrace transparency and the community, we welcome users to experience, explore, enjoy — and even learn from — our games.
          </p>
        </div>

        {/* Question 3 */}
        <div>
          <h2 className="text-xl font-semibold mb-2">3. Do I need to sign up for an account?</h2>
          <p className="mb-4">
            No, not at all. GameZoone.online is effortless and requires no sign-up. All you have to do is select a game and begin.
          </p>
        </div>

        {/* Question 4 */}
        <div>
          <h2 className="text-xl font-semibold mb-2">4. Does GameZoone.online take any personal information?</h2>
          <p className="mb-4">
            No, we do not collect any personal information, cookies, or tracking data. Your experience is 100% anonymous, and your privacy is 100% respected.
          </p>
        </div>

        {/* Question 5 */}
        <div>
          <h2 className="text-xl font-semibold mb-2">5. Is GameZoone.online compatible with mobile devices?</h2>
          <p className="mb-4">
            Yes, GameZoone.online is fully compatible with desktops and mobile devices, allowing you to play on your smartphone, tablet, or computer.
          </p>
        </div>

        {/* Question 6 */}
        <div>
          <h2 className="text-xl font-semibold mb-2">6. Which games are available on GameZoone.online?</h2>
          <p className="mb-4">
            GameZoone.online features simple, fun, and engaging games for all ages, including classic games, puzzles, and casual games to enjoy during breaks or for quick entertainment.
          </p>
        </div>

        {/* Question 7 */}
        <div>
          <h2 className="text-xl font-semibold mb-2">7. I have questions or feedback. Who do I contact?</h2>
          <p className="mb-4">
            We’d love to hear from you! If you have any questions or feedback, you can write to us at{' '}
            <a href="mailto:gamezoone.online@gmail.com" className="text-blue-500 underline">
              gamezoone.online@gmail.com
            </a>.
          </p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default FAQs;
