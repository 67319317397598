import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = ({ onSearchChange, onCategorySelect }) => {
  const [activeCategory, setActiveCategory] = useState("All");
  const [menuOpen, setMenuOpen] = useState(false); // State for toggle menu
const navigate  = useNavigate();
  const handleCategoryClick = (category) => {
    navigate("/");
    setActiveCategory(category);
    onCategorySelect(category === "All" ? "" : category);
    // console.log(activeCategory)
  };

  return (
    <header className="bg-gradient-to-r from-[#0e0033] via-[#1f1249] to-[#250958] p-6 flex flex-col md:flex-row justify-between items-center">
      {/* Logo */}
      <div className="flex justify-between w-full md:w-auto items-center">
        <div className="text-5xl font-bold text-white">
          <Link to="/">
            Game<span className="text-purple-300">Zoone</span>
          </Link>
        </div>
        {/* Toggle Button for Small Screens */}
        <button
          className="md:hidden text-white text-2xl"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          ☰
        </button>
      </div>

      {/* Search Input */}
      <div
        className={`${
          menuOpen ? "block" : "hidden"
        } md:flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4 w-full md:w-auto mt-4 md:mt-0`}
      >
        <div className="relative w-full md:w-auto">
          <input
            type="text"
            placeholder="Search your favorite game"
            onChange={(e) => onSearchChange(e.target.value)}
            className="pl-10 pr-4 py-2 rounded-full w-full md:w-80 bg-purple-600 text-white placeholder-gray-300 focus:outline-none"
          />
        </div>

        {/* Navigation Links */}
        <nav className="flex flex-wrap space-y-2 md:space-y-0 md:space-x-4">
          {[
            "All",
            "Adventure",
            "Plus-Minus",
            "Sports",
            "Zoone-Games",
            "Car",
            "Action",
          ].map((category) => (
            <button
              key={category}
              onClick={() => handleCategoryClick(category)}
              className={`px-4 py-2 rounded-full ${
                activeCategory === category
                  ? "bg-purple-500 text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
            >
              {category}
            </button>
          ))}
        </nav>
      </div>
    </header>
  );
};

export default Header;
